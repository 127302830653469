<template>
  <ion-page>
    <app-header back="home" title="출하현황집계" />
    <ion-content :fullscreen="true">
      <template v-if="isConnected">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <!-- <ion-col size-md="1" size-xs="2">
              <span> 일자</span>
            </ion-col> -->
            <ion-col size-md="1" size-xs="1" @click="dateBack">
              <ion-icon :icon="arrowBack"></ion-icon>
            </ion-col>
            <ion-col size-md="2" size-xs="5">
              <ion-input class="calendar" v-model="nowDate" id="nowDate" readonly @click="popChange">
              </ion-input>
              <ion-popover trigger="nowDate" @didDismiss="onWillDismissPopover">
                <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM-DD"  v-model="nowDate" presentation="date" :show-default-buttons="true">
                </ion-datetime>
              </ion-popover>
            </ion-col>
            <ion-col size-md="1" size-xs="1" @click="dateForward">
              <ion-icon :icon="arrowForward"></ion-icon>
            </ion-col>
            <ion-col size-md="2" size-xs="5">
              <ion-select
                placeholder="공장선택" interface="popover"
                :value="option.value"
                @ionChange="selectPart($event)">
                <ion-select-option value="%25">전체</ion-select-option>
                <ion-select-option value="1000">인천공장</ion-select-option>
                <ion-select-option value="2000">광양공장</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size-md="3" size-xs="6">
              <ion-button expand="block" class="excel" @click="exportToExcel" color="success">엑셀변환</ion-button>
            </ion-col>
            <ion-col size-md="3" size-xs="6">
              <ion-button expand="block" @click="onReConnect" color="medium">조회</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <vue-good-table 
          :columns="columns" 
          :rows="outtts" 
          :row-style-class="rowStyleFunction"
          :sort-options="{
            enabled: false,
          }"
          max-height="80vh"> 
       </vue-good-table>
      </template>
      <template v-else>
        <network-error v-on:@onReConnect="onReConnect" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonDatetime,
  onIonViewWillEnter,
  IonButton,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonPopover,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  popoverController,
  onIonViewWillLeave
} from '@ionic/vue';
import { defineComponent, reactive, ref, computed } from 'vue';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';
import { UseOuttt } from '@/api/services/outtt';
import { setDateFormat  } from '@/utils/dateFormatter';
import { arrowBack, arrowForward } from 'ionicons/icons';
import * as XLSX from 'xlsx';
import { useStore } from 'vuex';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    VueGoodTable,
    IonButton,
    IonInput,
    IonPopover,
    IonGrid,
    IonCol,
    IonRow,
    IonIcon,
    
  },
  setup() {
    const nowDate = ref(setDateFormat(Date.now(), 'YYYY-MM-DD'));
    const option = ref('%25');
    const { isConnected, fetchDatas, outtts } = UseOuttt(); 
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '출하현황집계');
    });
    const form = reactive({
      popoverState: false
    });
    async function onWillDismissPopover() {
      form.popoverState = false;
    }
    async function popChange() {
      form.popoverState = true;
    }

    function customd01(row) {
      if (row.sale_qunt) return row.sale_qunt.toLocaleString('ko-KR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
    function customd02(row) {
      if (row.sale_qunt_t) return row.sale_qunt_t.toLocaleString('ko-KR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
    function customd03(row) {
      if (row.month_qunt) return row.month_qunt.toLocaleString('ko-KR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
    function customd04(row) {
      if (row.month_qunt_t) return row.month_qunt_t.toLocaleString('ko-KR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
    function customc(row) {
      if ( row.sale_qunt || row.sale_qunt_t || row.month_qunt || row.month_qunt_t ) return 'currency';
    }

    const columns = reactive([
      {
        label: '품목',
        field: 'itnmname_s',
        width: '100px',
      },
      {
        label: '영업소',
        field: 'office_name',
        width: '100px',
      },
      {
        label: '출하',
        field: customd01,
        width: '71px',
        tdClass: customc,
      },
      {
        label: '출하(t)',
        field: customd02,
        width: '100px',
        tdClass: customc,
      },
      {
        label: '월계',
        field: customd03,
        width: '71px',
        tdClass: customc,
      },
      {
        label: '월계(t)',
        field: customd04,
        width: '100px',
        tdClass: customc,
      },
    ]);

    onIonViewWillEnter(async () => {
      //
    });

    function selectPart(e) {
      option.value = e.target.value;
    }

    async function onReConnect() {
      const frdutiesPromise = fetchDatas(option.value, nowDate.value.substring(0, 10).replaceAll('-', ''));
      await frdutiesPromise;
    }

    const exportToExcel = () => {
      const data =  Object.values(outtts.value).map(row => [row.itnmname_s, row.office_name, row.sale_qunt, row.sale_qunt_t, row.month_qunt, row.month_qunt_t,]);
      data.unshift(columns.map(col => col.label));
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "출하현황집계.xlsx");
    }

    function rowStyleFunction(row) {
      if (row.office_name === '소   계 :') {
        return 'row-subtotal-color';
      }
    }

    onIonViewWillLeave(async () => {
      if (form.popoverState == true) {
        await popoverController.dismiss()
      }
    });

    function dateForward() {
      const date = nowDate.value.replaceAll('-', '');
      const year = date.substr(0, 4);
      const month = date.substr(4, 2);
      const day = date.substr(6, 2);
      const dateTo = new Date(Number(year), Number(month) - 1, Number(day)) ;
      nowDate.value = setDateFormat(dateTo.setDate(dateTo.getDate() + 1), 'YYYY-MM-DD'); 
    }

    function dateBack() {
      const date = nowDate.value.replaceAll('-', '');
      const year = date.substr(0, 4);
      const month = date.substr(4, 2);
      const day = date.substr(6, 2);
      const dateTo = new Date(Number(year), Number(month) - 1, Number(day)) ;
      nowDate.value = setDateFormat(dateTo.setDate(dateTo.getDate() - 1), 'YYYY-MM-DD'); 
    }

    return {
      isConnected,
      nowDate,
      columns,
      outtts,
      onReConnect,
      exportToExcel,
      selectPart,
      option, 
      arrowBack, 
      arrowForward,
      rowStyleFunction,
      onWillDismissPopover,
      popChange,
      dateForward,
      dateBack,
    };
  }
});

</script>
<style scoped>
ion-content {
  text-align: center;
}
ion-col {
  padding: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  color: black;
  width: 100%;
}
ion-col .calendar {
  padding: 0;
}
.title {
  display: block;
  text-align: center;
  font-size: 1.2em;
  padding: 0.5em;
}
ion-grid {
  padding: 0;
}
ion-datetime {
  width: 100%;
} 
</style>