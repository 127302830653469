import { Ref, ref } from 'vue';
import { $loading } from '@/api/index';
import { toast } from '@/utils/toast';

export interface Outtt {
  itnmcode: string;
  itnmname_s: string;
  office_name: string;
  sale_qunt: number;
  sale_qunt_t: number;
  month_qunt: number;
  month_qunt_t: number;
}

export function UseOuttt() {

  const outtts: Ref<Outtt[]> = ref([]);
  const isConnected = ref(true);

  async function fetchDatas(pcSicd: string, pcSldt: string) {
    await $loading(`/outtt?pc_sicd=${pcSicd}&pc_sldt=${pcSldt}`, 'POST').then(
      (res: any) => {
        if (res.data == ''){
          outtts.value = [];
          toast('데이터가 존재하지 않습니다.'); 
        } else {
          outtts.value = null;
          outtts.value = res.data;
        }
      }
    );
  }      

  return {
    isConnected,
    fetchDatas,
    outtts,
  };
}
